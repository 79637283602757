import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Em, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Knockout Academy
			</title>
			<meta name={"description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:title"} content={"Knockout Academy"} />
			<meta property={"og:description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Headernew />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.29) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z) center/cover"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			min-height="80vh"
			margin="auto 0 auto 0"
			align-items="center"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Açık Bir Tarz, Akıcı Bir Deneyim
					</Text>
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Knockout Academy
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Knockout Academy'ye hoş geldiniz. Burada boks sadece bir spor değil, aynı zamanda bir tutkudur. İster yeni başlayan ister deneyimli bir dövüşçü olun, hedeflerinize ulaşmanıza yardımcı olacak bir ortam sunuyoruz.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08:54:09.265Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="500px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab35a6995d120018f291cc/images/2-4.jpg?v=2024-08-01T08%3A54%3A09.265Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					<Em>
						Knockout Academy, boks sanatına olan tutkumuzla kuruldu
					</Em>
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Knockout Academy'de, ekibimiz yüksek kalitede eğitim sunma konusunda kararlıdır. İster rekabetçi bir boksör olun ister sadece formunuzu korumak isteyin, size özel bir eğitim programı oluşturuyoruz. Başarınızı garanti altına almak için buradayız.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Neden bizi tercih etmelisiniz?
			</Text>
		</Section>
		<Section padding="0px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Uzman Antrenörler
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Ekibimiz, mesleklerinde uzman, deneyimli boks eğitmenlerinden oluşmaktadır.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Rahat Ortam
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Dinlenmeniz ve rahatlamanız için huzurlu ve davetkar bir ortam sunuyoruz.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Kişiye Özel Hizmet
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Her ziyaret, ihtiyaçlarınıza göre uyarlanır ve istediğiniz sonuçları elde etmenizi sağlar.
				</Text>
			</Box>
		</Section>
		<Section padding="0px 0 120px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Yüksek Kaliteli Ürünler
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Saç ve cildinizin en iyi bakımı için birinci sınıf ürünler kullanıyoruz.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Ulaşılabilir Konum
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Geniş otoparkımızla kolay erişilebilir konumdayız, bu da ziyaretinizi sorunsuz hale getirir.
				</Text>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/1-1.jpg?v=2024-08-01T08:54:09.568Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Knockout Academy'de, ihtiyaçlarınıza göre tasarlanmış geniş bir hizmet yelpazesi sunuyoruz.
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="70%"
				>
					Boks becerilerinizi geliştirmeye hazır mısınız? Knockout Academy'de, biz sadece bir spor salonu değiliz; boksa olan sevgisini paylaşan hevesli bireylerden oluşan bir topluluğuz. Bize katılarak, motive eden ve ilham veren destekleyici bir ağın parçası olacaksınız. Üyelerimiz yalnızca olağanüstü bir eğitimden değil, aynı zamanda bir yoldaşlık duygusundan ve ortak bir amaçtan da keyif alıyor. Beklemeyin; farkı deneyimleyin ve neden bu kadar çok kişinin Knockout Academy'yi eğitim yuvası olarak seçtiğini görün.
				</Text>
				<Link
					href="/menu"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Hizmetlerimiz
				</Link>
			</Box>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});